/* eslint-disable vue/no-unused-components */
<template>
  <div>
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <!-- <div id="progressBar">
    <div class="loader"></div>
      </div>-->
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-9">
              <div class="heading-profile">
                <h3>
                  Wed Calculators
                </h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="widget-title">
                    <div class="float-right"></div>
                    <div class="d-flex">
                      <div>
                        <h6>
                          List of Users Download Info
                          <span class="tableTotal">(Total : {{ totalCount }})</span>
                        </h6>
                      </div>
                      <div class=" ml-3 ">
                        <b-button variant="success" @click="generateExcelForAllwedCal(page)"
                          >Download</b-button
                        >
                      </div>
                      <div class="ml-auto">
                        <form class="search-form mr-form">
                          <input
                            type="text "
                            class="form-control"
                            v-model="searchText"
                            @input="viewHotelList(1)"
                            placeholder="Search Here..."
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive" v-if="wedCalculatedPdfs.length >= 1">
                    <table class="table text-nowrap">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>User Name</th>
                          <th>Date of using Calculator</th>
                          <th>City</th>
                          <th>State</th>
                          <th>Country</th>
                          <th>Last Login Date &amp; Time</th>
                          <th>Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in wedCalculatedPdfs" v-bind:key="item._id">
                          <td>{{ index + 1 + (page - 1) * 10 }}</td>
                          <td>
                            {{ item.user.name }}
                          </td>
                          <td>
                            {{ moment(item.createdAt).format("DD/MM/YYYY") }}
                          </td>
                          <td>
                            {{ item.user.city }}
                          </td>
                          <td>
                            {{ item.user.state }}
                          </td>
                          <td>
                            {{ item.user.country }}
                          </td>
                          <td>
                            {{
                              moment(
                                item.user.loginData[item.user.loginData.length - 1].lastLogin
                              ).format("DD/MM/YYYY hh:mm A")
                            }}
                          </td>
                          <td @click="dowloadAndOpenPdf(item.pdfFile)">
                            <div>Download</div>
                          </td>
                        </tr>
                        <div>
                          <deletemodal
                            :mymodal="blogData"
                            v-on:conformModal="deleteBlogs"
                          ></deletemodal>
                        </div>
                        <!-- end v-repeat -->
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                    v-if="wedCalculatedPdfs.length === 0"
                  >
                    No Data Found
                  </div>
                  <div class="text-center" v-if="wedCalculatedPdfs.length >= 1">
                    <b-pagination
                      class="mb-0 float-right"
                      v-model="page"
                      align="center"
                      :total-rows="totalCount"
                      :per-page="formData.limit"
                      @input="getAllWedCalculatedPdfList(page)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";
import deletemodal from "@/components/deletemodal.vue";
import moment from "moment";

export default {
  components: {
    headerCustom,
    deletemodal
  },
  data() {
    return {
      id: "",
      logs: {
        idToDelete: ""
      },
      moment,
      currentPage: 1,
      wedCalculatedPdfs: [],
      searchText: "",
      users: [],
      page: 1,
      formData: { limit: 10 },
      totalCount: 0,
      perPage: 10,
      errors: [],
      data: "",
      email: "",
      blogData: {}
    };
  },
  methods: {
    viewBlogList(pageNo) {
      this.users = [];
      this.formData.page = pageNo;
      this.formData.limit = this.perPage;
      this.formData.search = this.searchText;
      service.viewBlogs(this.formData, data => {
        if (data.status === 200) {
          this.users = data.data.result;
          this.totalCount = data.data.count;
        } else {
        }
      });
    },
    getAllWedCalculatedPdfList(pageNo) {
      this.formData.page = pageNo;
      service.getAllWedCalculatedPdfList(this.formData, data => {
        if (data.status == 200) {
          this.wedCalculatedPdfs = data.data.result;
          this.totalCount = data.data.count;
        } else {
        }
      });
    },
    generateExcelForAllwedCal() {
      service.generateExcelForAllwedCal({}, data => {
        service.downloadExcel(data, "WedCal_List");
      });
    },
    dowloadAndOpenPdf(pdfUrl) {
      window.open(pdfUrl);
    },
    deleteBlogById(user) {
      this.blogData = user;
    },
    deleteBlogs(blog) {
      service.deleteBlogs(blog._id, result => {
        if (result.code == 200) {
          this.$toaster.success("Blogs Deleted");
          this.viewBlogList(1);
        } else {
          this.$toaster.errors("Something went wrong");
        }
      });
    }
  },
  created() {
    this.getAllWedCalculatedPdfList(1);
    // this.viewBlogList(this.page);
  }
};
</script>

<style></style>
